@import './_variable-dark';

body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    src: url("/assets/fonts/Roboto-Regular.ttf");
    background: var(--brand-blueBlack-3);
}

.ant-layout {
    background: var(--brand-blueBlack-3);
}

.content-container {
    padding: 15px;
}

.content-container {
    padding: 15px;
}

.right-layout-250 {
    position: fixed;
    left: 250px;
    transition: all 0.2s;
}

.display-body {
    height: auto;
  }

.display-main {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 100vh; 
}

.right-layout-80 {
    position: fixed;
    left: 80px;
    transition: all 0.2s;
}

.ant-layout-footer {
    background: var(--brand-blueBlack-3);
}

.text-white {
    color: #fff;
}

.text-underline {
    text-decoration: underline;
}

/*---------------------- Fonts and Font-size ----------------------------*/
.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fw-bold {
    font-weight: 600 !important;
}

.heading-fs-12 {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 600;
}

.heading-fs-14 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.heading-fs-16 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.heading-fs-18 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

.heading-fs-20 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.heading-fs-24 {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.font-poppins {
    font-family: "Poppins", sans-serif;
}

.list-item:hover {
    background: #fafafa;
}

body.darkmode .list-item:hover {
    background: var(--brand-blueBlack-10);
}

/*---------------------- Form Fields ----------------------------*/

label.required::after {
    content: ' *';
    color: #d13438;
    font-size: 14px;
    line-height: 1;
    font-family: SimSun, sans-serif;
    display: inline-block;
    margin-left: 4px;
}

.ant-form-item-label {
    position: absolute;
    top: -30px;
    font-size: 14px;
    overflow: hidden;
    // font-weight: bold;
}

.ant-form-item {
    margin: 0px;
}

.ant-form-item-control {
    margin-bottom: 16px;
}

.ant-form-item-label>label::after {
    content: none;
}

.header-input .ant-input {
    line-height: 0;
}

.ant-input-group>.ant-input:last-child,
.ant-input-group-addon:last-child {
    border-radius: 5px;
}

.ant-input,
.ant-input-affix-wrapper {
    border: 1px solid hsl(0, 0%, 85%);
    border-radius: 5px;
    padding: 7px 11px;
}

.header-input .ant-input-affix-wrapper {
    border: 2px solid var(--color-bg-gray1);
    border-radius: 25px;
    padding: 7px 11px;
}

.drawer-search-input .ant-input-affix-wrapper {
    border: 1px solid var(--color-bg-gray1);
    border-radius: 25px;
    padding: 7px 11px;
    background-color: var(--brand-blueBlack-3);
}

.drawer-search-input .ant-input-affix-wrapper>.ant-input:not(textarea) {
    background-color: var(--brand-blueBlack-3);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid hsl(0, 0%, 85%);
    border-radius: 5px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: auto;
    padding: 3px 11px;
}

.collpase-d-none .ant-select-selection-item {
    display: none;
}

.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: auto;
    padding: 3px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 36px;
}

.ant-select-multiple .ant-select-selection-item {
    background: transparent;
    border: none;
    padding-inline-start: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-end: 0px;
}

.ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 0px;
}

.ant-select-arrow {
    color: var(--text-Black);
}

.ant-select-arrow .anticon {
    font-size: 12px;
}

.ant-select-clear {
    top: 40%;
}

.dashboard-dropdown .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 150px;
    height: auto;
}

.sort-by-dropdown .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 120px;
    height: auto;
    padding: 0px 11px;
}

.sort-by-dropdown .ant-select-selection-placeholder {
    color: var(--text-Black);
}

.header-input .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 2px solid var(--color-bg-gray1);
    border-radius: 25px;
}

.header-input .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: auto;
    padding: 3px 11px;
}

.ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
    opacity: 50%;
}

.select-prefix-icon {
    position: absolute;
    z-index: 100;
    top: 21%;
    left: 11px;
}

.form-field-prefix-icon {
    position: absolute;
    z-index: 100;
    top: 29%;
    left: 11px;
}

.header-input .ant-select-selector {
    padding-left: 32px !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: #fff;
    border-color: #C50F1F;
    color: #B10E1C;
}

.validation-error-message {
    position: relative;
    bottom: 0px;
    font-size: 12px;
    margin-left: 2px;
    color: #B10E1C !important;
    text-align: left;
}

.cursor-pointer {
    cursor: pointer;
}

.ant-input-affix-wrapper .ant-input-affix-wrapper-status-error {
    border-color: grey;
}

.input-container {
    min-height: 40px;
    max-height: auto;
    margin-top: 24px;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    display: inline-block;
    margin-left: 4px;
    margin-right: 18px;
    color: #D13438;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.small-input {
    height: 37px;
    width: 85px;
    margin-left: 15px;
    margin-right: 15px;
}

.ant-checkbox-inner {
    border-radius: 2.5px;
}

.ant-select-arrow {
    color: var(--text-Black);
}

.ant-picker-suffix {
    color: var(--text-Black);
}

.ant-picker-suffix .anticon {
    font-size: 14px;
}

.ant-picker {
    padding: 7px 11px;
    border: 1px solid var(--color-bg-gray1);
    border-radius: 5px;
    width: 100%;
}

.ant-picker-input>input {
    text-overflow: ellipsis;
}

.ant-picker-time-panel-column:last-child {
    height: auto;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ant-picker-time-panel-column:last-child .ant-picker-time-panel-cell {
    flex: none;
    width: 100%;
}

.selected-square .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: var(--brand-blue-100);
}

.selected-square .ant-checkbox-checked .ant-checkbox-inner:after {
    border: none;
    transform: rotate(90deg) scale(1) translate(-51%, 3%);
    width: 8px;
    height: 8px;
    background: var(--brand-blue-100);
    border-radius: 2px;
}

.form-action-bar {
    padding: 10px 15px;
    height: auto;
    background: var(--card-bg-white);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 25;
    padding-left: 250px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
}

.mobOne-action-m-b {
    margin-bottom: 50px !important;
}

.hide-max-placeholder .ant-select-multiple .ant-select-selection-item {
    display: none;
}


/*---------------------- segment ----------------------------*/

.ant-segmented {
    height: 34px;
    border-radius: 7px;
    background-color: transparent;
    border: 1px solid var(--brand-blueBlack-20);

    &-:hover {
        background-color: transparent;
    }
}

.ant-segmented label {
    margin-bottom: 0px;
    color: var(--brand-blueBlack-60);
}

.ant-segmented-item-label {
    padding: 0 8px;
}

.ant-segmented-item-selected {
    background-color: #2C92EA;
    border-radius: 2px;
    color: #fff !important;
    border-radius: 5px;
}

.ant-segmented-item-icon+* {
    margin-left: 0px;
}

/*---------------------- Folder wise listing view ----------------------------*/

.folder-list-panel {
    position: fixed;
    top: 60px;
    width: 236px;
    height: 100%;
    background: var(--brand-blueGray-5);
    border-right: 1px solid var(--brand-blueGray-20);
}

.header-content {
    padding: 15px 15px 0px 15px;
}

.panel-ml {
    margin-left: 236px;
}

.main-content {
    padding: 5px;
}

.folder-list-container {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.folder-list-container:hover {
    color: var(--brand-blue-100);

    .list-icon {
        color: var(--brand-blue-100);
    }
}

.folder-item-selected {
    background-color: var(--brand-blueDark-20);

    .list-icon {
        color: var(--brand-blue-100);
    }
}

.image-conatiner {
    width: 22px;
    max-width: 22px;
    height: 22px;
    border-radius: 50%;
}

.image-conatiner img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.panel-action-btn {
    position: fixed;
    bottom: 15px;
    width: 225px;
}

/*---------------------- ant switch  ----------------------------*/

.ant-switch {
    min-width: 28px;
    height: 22px;
    background-color: transparent;
    border: 2px solid #000;
}

.ant-switch-checked {
    border: 2px solid var(--brand-blue-100);
    background-color: var(--brand-blue-100);
}

.ant-switch-handle {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    transition: all .2s ease-in-out;
}

.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 14px);
}

.ant-switch-handle:before {
    background-color: #000;
    box-shadow: 0px 2px 4px rgba(0, 35, 11, 0.2);
}

.ant-switch-checked .ant-switch-handle:before {
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 35, 11, 0.2);
}

/*---------------------- Spinner -------------------------*/

.ant-spin-nested-loading>div>.ant-spin {
    position: fixed;
    left: 0;
    right: 0;
    height: 100vh;
    max-height: 100vh;
}

.custom-spin .ant-spin-nested-loading>div>.ant-spin {
    position: relative;
    height: auto;
}

.ant-spin-dot-item {
    width: 62px;
    height: 62px;
    border-right: 5px solid var(--brand-blue-100);
    border-left: 5px solid var(--brand-blue-100);
    border-top: 5px solid var(--brand-blue-100);
    border-bottom: 5px solid var(--brand-blue-50);
    background-color: transparent;
    border-radius: 50%;
}

.ant-spin-dot {
    font-size: 62px;
    width: 62px;
    height: 62px;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-dot {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

/*---------------------- End ----------------------------*/

/*---------------------- scrollbar----------------------------*/

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-thumb {
    background: #ababab;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    border: 0;
    background-color: #b1b1b1;
}

::-webkit-scrollbar-track {
    background: transparent;
}

/* Scrollbar end*/

/*---------------------- Button ----------------------------*/

button:focus {
    outline: none;
}

a.ant-btn {
    padding-top: 0.99px !important;
}

.ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    min-height: 32px;
    line-height: 0px;
}

.ant-btn .anticon {
    font-size: 14px;
}

.ant-btn:hover,
.ant-btn:focus {
    .btn-icon {
        filter: invert(46%) sepia(19%) saturate(4923%) hue-rotate(187deg) brightness(100%) contrast(84%);
    }
}

.ant-btn-circle {
    min-width: 37px;
    text-align: center;
    border-radius: 50%;
}

.btn-full {
    width: 100%;
}

.btn-transparent {
    background: transparent;

    &:hover {
        background: transparent;
    }

    &:active {
        background: transparent;
        color: #fff;
    }

    &:focus {
        background: transparent;
        color: #fff;
    }
}

.btn-small {
    min-height: 24px !important;
    height: 24px;
    width: 24px;
}

.icon-btn {
    padding: 4px 7px;
}

.ant-btn>span {
    display: inline-flex;
}

.selected-button {
    color: #1890ff;
    border-color: #1890ff;
}

/*---------------------- Toastr ----------------------------*/

.ant-notification-notice {
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
    max-width: 340px;
}

.ant-notification-notice-message {
    display: none;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 20px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    max-width: 255px;
}

.ant-notification-notice-icon {
    position: absolute;
    margin-left: -5px;
    margin-top: 3px;
}

/*---------------------- End ----------------------------*/


.card,
.card-body {
    border-radius: 10px;
}

.ant-card-body {
    padding: 15px;
    overflow: hidden;
}

.ant-card-bordered {
    border: 1px solid #f0f0f0;
    border-radius: 10px;
}

.box-shadow {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
}

.ant-card-body {
    padding: 24px;
}

.media-card .ant-card-body {
    padding: 12px 10px !important;
}


.streaming-card .ant-card-body {
    padding: 24px 24px 0px 24px !important;
}

.list-card .ant-card-body {
    padding: 6px 16px !important;
}

.ant-card-bordered {
    border: 1px solid #f0f0f0;
    border-radius: 10px;
}

.ant-collapse-header {
    font-size: 15px;
    font-weight: 500;
}

.ant-divider-horizontal {
    margin: 10px 0;
}

.ant-divider-vertical {
    height: 20px;
    margin: 0px;
    border-left: 2px solid var(--brand-blueBlack-40);
}

.ant-menu-item .anticon {
    font-size: 16px;
}

.anticon {
    font-size: 16px;
}

.icon-fs-28 {
    font-size: 28px !important;
}

.ant-menu-item::after {
    border: none !important;
}

.ant-dropdown-menu-item:hover {
    border-radius: 10px;
}

.align-right {
    display: flex;
    justify-content: end;
}

.ant-menu-vertical .ant-menu-item {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.ant-menu-vertical .ant-menu-submenu-title {
    padding: 0px 13px;
}

/*---------------------- Dialog -------------------------*/

.ant-modal-wrap {
    align-content: center;
}

.ant-modal-content {
    border-radius: 8px;
}

.ant-modal-header {
    border-bottom: none;
    border-radius: 8px !important;
}

.ant-modal-title {
    font-size: 20px;
    font-weight: 600;

}

.ant-modal-footer {
    border-top: none;
}

.ant-modal-body {
    padding: 16px 24px;
    overflow: auto;
}

.ant-modal {
    top: 0;
    width: 60vw !important;
}

.custom-width-450 .ant-modal {
    max-width: 450px !important;
}

.custom-width-600 .ant-modal {
    width: 600px !important;
}

@media (max-width: 768px) {
    .ant-modal {
        min-width: auto;
    }

    .display-main {
        text-align: center;
    }
    .display-main .logo {
        height: 40px;
        width: auto;
    }
}

/*-------------------------- Form Component Styling ----------------------------*/

.w-100 {
    width: 100%;
}

.select-option:hover {
    border-color: #57b2f7;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.14);
    background: #fff;
    z-index: 1
}

.option-selected {
    border-color: #57b2f7;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.14);
    background: #fff;
    z-index: 1
}

.orientation-conatiner {
    max-width: 147px;
    height: 100px;
    max-height: 100px;
    text-align: center;
}

.orientation-conatiner img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: auto;
}

.media-type-header {
    background: var(--color-bg-bluelight);
    border: 1px solid var(--brand-blueBlack-30);
    border-radius: 8px;
    padding: 16px;

    &-content {
        color: #70767D;
        align-content: center;
    }

    &-img-wrapper {
        width: 32px;
        height: 32px;
    }
}

.content-center {
    align-content: center;
    align-self: center;
}

/*---------------------- Ant tabs -------------------------*/
.ant-tabs-tab {
    padding: 9px 0px;
}

.ant-tabs-top>.ant-tabs-nav {
    margin: 0px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--text-Black);
    text-shadow: none;
}

/*------------------------------------- Custom spacing and alignment classes --------------------------------------------*/

.text-ul {
    text-decoration: underline;
}

.text-ellipsis {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}

.justify-content-center {
    display: flex;
    justify-content: center !important;
}

.align-items-center {
    display: flex;
    align-items: center !important;
}

.page-header {
    padding: 15px;
    height: 95px;
    position: relative;
    top: -19px;
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
}

.page-header-with-tabs {
    padding: 15px 15px 0px 15px;
    height: 95px;
    position: relative;
    top: -19px;
    background: var(--card-bg-white);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
}

.form-page-header {
    padding: 13px 15px;
    height: auto;
    background: #fff;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 25;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
}

body.darkmode {
    .form-page-header {
        background: var(--brand-blueBlack-0);

    }

    .ant-breadcrumb>nz-breadcrumb-item:last-child {
        color: var(--text-white);
    }

    .ant-breadcrumb,
    .ant-breadcrumb-separator {
        color: var(--brand-blueGray-50);
    }
}

::ng-deep .ant-layout.ant-layout-has-sider.ant-layout-sider-collapsed .form-page-header {
    width: calc(100% - 80px) !important;
}

.form-header-m-t-30 {
    margin-top: 30px;
}

.form-header-m-t-80 {
    margin-top: 80px;
}

.drawer-form-header {
    position: sticky;
    background: #fff;
    z-index: 1000;
    width: 100%;
    top: 0;
    margin-right: 20px;
}

.drawer-form-header-empty {
    position: fixed;
    top: 0;
    height: 15px;
    background: #fff;
    z-index: 1000;
    width: 100%;
}

.drawer-form-mt-60 {
    margin-top: 60px;
}

.breadcrumb {
    background-color: transparent;
    padding: 0px;
    margin-bottom: 0px;
}

.breadcrumb-previous-link:hover {
    color: #2c92ea;
}

.ant-breadcrumb {
    font-size: 12px;
}

.preview-card {
    position: sticky;
    top: 125px;
    min-height: calc(100vh - 195px);
}

.preview-display-container {
    max-width: 450px;
    max-height: 240px;
}

.preview-display-container img {
    width: 100%;
    height: auto;
}

.preview-content {
    display: flex;
    align-items: center;
    background: #E7E8E9;
    border: 1px solid var(--brand-blueBlack-20);
    border-radius: 4px;
    padding: 9px 16px 9px 16px;
    font-size: 12px;
}

.preview-chip {
    background: var(--brand-blue-10);
    border: 1px solid #C0DEF9;
    padding: 2px 10px;
    border-radius: 100px;
    width: fit-content;
}

.dashboard-tile-chip {
    background: #C0DEF9;
    padding: 1px 10px;
    border-radius: 100px;
    width: fit-content;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
}

.text-blue {
    color: #2C92EA;
}

.picker {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.14);
}

.h-100-percent {
    height: 100%;
}

.h-400 {
    min-height: 400px;
    max-height: 400px;
}

.min-h-350 {
    min-height: 350px;
}

.d-card-min-h-440 {
    min-height: 440px;
    max-height: 440px;
}

.d-card-min-h-415 {
    min-height: 415px;
}

.d-empty-container {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-table-thead>tr>th {
    padding: 5px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.ant-table-tbody>tr>td {
    padding: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 6px;
}

.ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 6px;
}

.table-image-container {
    min-width: 22px;
    width: 22px;
    min-height: 22px;
    height: 22px;
}

.table-image-container img {
    width: 22px;
    height: 22px;
    border-radius: 4px;
}

.table-image-scrn-container {
    min-width: 40px;
    width: 40px;
    min-height: 25px;
    height: 25px;
}

.table-image-scrn-container img {
    width: 40px;
    height: 25px;
    border-radius: 4px;
}

.table-icon {
    font-size: 14px;
}

.status-icon-success {
    color: #00CC6A;
    font-size: 16px;
}

.status-icon-danger {
    color: #B10E1C;
    font-size: 16px;
}

.status-icon-pending {
    color: #F7630C;
    font-size: 16px;
}

.label-icon {
    margin-left: 5px;
    color: var(--brand-blueBlack-30);
    rotate: 180deg;
    font-size: 14px;
}

body.darkmode {

    .label-icon.filter_darkmode,
    .label-icon,
    .label-icon-v2,
    .anticon.label-icon-v1 {
        color: var(--brand-blueBlack-40);
        filter: none;
    }
}

.label-icon-v1 {
    position: absolute;
    right: 0;
    color: #B8BBBE;
    rotate: 180deg;
    font-size: 14px;
}

.label-icon-v2 {
    position: absolute;
    margin-top: 4px;
    margin-left: 15px;
    color: #B8BBBE;
    rotate: 180deg;
    font-size: 14px;
}

.player-container {
    max-width: 30px;
    max-height: 30px;
}

.h-100-percent {
    height: 100%;
}

.ant-table-thead>tr>th {
    padding: 5px;
    background-color: #E7E8E9;
    font-weight: 600;
}

.ant-table-thead th.ant-table-column-sort {
    background-color: #E7E8E9;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #E7E8E9;
}

body.darkmode .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: var(--brand-blueBlack-10);
}

.ant-table-tbody>tr>td {
    padding: 12px;
}

.ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 6px;
}

.ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 6px;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
    font-size: 12px;
}

.ant-table-column-title {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-table-column-sorter {
    color: var(--text-Black);
}

.ant-table-column-sorters:hover .ant-table-column-sorter {
    color: var(--text-Black);
}

.ant-table-pagination-right {
    justify-content: flex-start;
}

.table-icon {
    font-size: 14px;
}

.play-icon{
    font-size: 20px !important;
}

.status-icon-success {
    color: #00CC6A;
    font-size: 16px;
}

.status-icon-inactive {
    color: #D9D9D9;
    font-size: 16px;
}

.status-inactive {
    width: 14px;
    height: 14px;
    border-radius: 30px;
    background: var(--color-bg-gray1);
}

.status-active {
    width: 14px;
    height: 14px;
    border-radius: 30px;
    background: #00CC6A;
}

.text-gray {
    color: var(--text-blueBlack-50);
}

.player-container img {
    width: 100%;
    height: auto;
}

.ant-drawer-right>.ant-drawer-content-wrapper {
    width: 600px !important;
}

.ant-drawer-body {
    background: #fff !important;
    padding: 15px;
}

.sort-input {
    min-width: 150px;
}

.bg-blue {
    background: var(--brand-blue-10);
}

.card-with-title .ant-card-body {
    padding: 0px !important;
}

.card-heading {
    padding: 16px 15px 16px 15px;
    border-radius: 10px 10px 0px 0px;
}

.card-content {
    padding: 15px;
}

.subscription-grid {
    display: flex;
    // justify-content: space-between;
    gap: 50px;
}

.disabled-icon {
    opacity: 0.5;
    cursor: not-allowed;
}

.custom-chip {
    min-height: 24px;
    font-size: 12px;
    width: fit-content;
    padding: 5px 8px;
    gap: 4px;
    border-radius: 100px;
    text-wrap: nowrap;
}

.chip-success {
    background-color: #359B35 !important;
    color: #fff;
}

.success-dialog {
    padding: 20px 0px;
}

.success-dialog-img {
    width: 150px !important;
    height: 150px !important;
}

.payment-card-actions {
    display: grid;
    justify-content: end;
}

.player-container {
    max-width: 30px;
    max-height: 30px;
}

.player-container img {
    width: 100%;
    height: auto;
}

.player-type-icon {
    width: 18px;
    height: 18px;
}

.player-type-icon img {
    width: 100%;
    height: auto;
}

.ant-drawer-right>.ant-drawer-content-wrapper {
    width: 600px !important;
}

.ant-drawer-body {
    background: #fff !important;
    padding: 15px;
}

.sort-input {
    min-width: 150px;
}

.sort-button {
    height: 32px;
    width: 100px;
    border: 1px solid var(--brand-blueBlack-30);
    border-radius: 6px;
    justify-content: center;
}

.list-default-wrapper {
    min-width: 38px;
    width: 38px;
    height: 38px;
    min-height: 38px;
    border-radius: 10px;
    border: 1px solid var(--brand-blueBlack-20);
    background: var(--color-bg-gray1);
}

.table-list-default-wrapper {
    min-width: 22px;
    width: 22px;
    height: 22px;
    min-height: 22px;
    border-radius: 5px;
    border: 1px solid var(--brand-blueBlack-20);
    background: var(--color-bg-gray1);
}

.table-list-default-scrn-wrapper {
    min-width: 40px;
    width: 40px;
    height: 25px;
    min-height: 25px;
    border-radius: 5px;
    border: 1px solid var(--brand-blueBlack-20);
    background: var(--color-bg-gray1);
}

.list-img-wrapper {
    width: 38px;
    height: 38px;
}

.list-img-wrapper img {
    width: 38px;
    height: 38px;
}

.table-list-img-wrapper {
    width: 22px;
    height: 22px;
}

.table-list-img-wrapper img {
    width: 22px;
    height: 22px;
}

.bg-blue {
    background: var(--brand-blue-10);
}

.card-with-title .ant-card-body {
    padding: 0px !important;
}

.payment-card-container {
    background: #fff;
    border: 1px solid var(--brand-blueBlack-20);
    border-radius: 10px;
    padding: 16px;
}

body.darkmode {
    .payment-card-container {
        background: var(--brand-blueBlack-20);
        color: var(--text-white);
    }

    .payment-card-chip {
        background: var(--brand-blue-10);
    }
}

.payment-card-img {
    width: 60px;
    height: 32px;
    margin-right: 15px;
}

.payment-card-chip {
    min-height: 22px;
    font-size: 12px;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 64px;
    gap: 8px;
    text-wrap: nowrap;
    align-content: center;
    background: var(--brand-blue-100);
    color: #fff;
}

.card-type {
    margin-bottom: 10px;
}

.bg-gray {
    background: var(--brand-blueBlack-3);
}

.small-input {
    height: 35px;
    width: 80px;
    margin-left: 15px;
    margin-right: 15px;
}

.btn-small {
    min-height: 22px !important;
    height: 22px;
    width: 22px;
}

.ant-small-icon {
    font-size: 12px;
}

.form-field-btn {
    min-height: 36px;
}

.card-content {
    padding: 15px;
}

.custom-chip {
    min-height: 24px;
    width: fit-content;
    padding: 2px 8px;
    gap: 4px;
    border-radius: 100px;
    text-wrap: nowrap;
}

.chip-primary {
    background-color: var(--brand-blue-100);
    color: #fff;
}

.clear-search-container {
    width: 12px;
}

.clear-search {
    font-size: 12px;
}

.disp-web {
    display: block;
}

.disp-web-subs {
    display: flex;
}

.disp-web-button {
    display: inline-flex;
}

.disp-mob {
    display: none !important;
}

.disp-mob-subs {
    display: none !important;
}

.mobOne-action-bar {
    width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
}

.overflow-hidden {
    overflow: hidden;
}

.image-container .hidden {
    display: none !important;
}

.status-icon-danger {
    color: #BC4B09;
    font-size: 16px;
}

.status-icon-primary {
    color: var(--brand-blue-100);
    font-size: 16px;
}

.text-muted {
    color: var(--brand-blueGray-70);
}

.text-primary {
    color: var(--brand-blue-100);
}

.w-70 {
    width: 70px;
}

.drawer-back-arrow {
    font-size: 14px;
}

/*-------------------------- Form Component Styling ----------------------------*/


.streaming-container {
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    padding: 7px 10px;
    border: 1px solid #E7ECF0;
    border-radius: 10px;
}

.disabled-streaming-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 7px 10px;
    border: 1px solid #E7ECF0;
    border-radius: 10px;
    background: #F2F3F4 !important;
    color: var(--brand-blueBlack-30) !important;

    .streaming-icon {
        filter: invert(81%) sepia(0%) saturate(501%) hue-rotate(285deg) brightness(91%) contrast(100%);
    }
}

.select-option:hover {
    border-color: #57b2f7;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.14);
    background: #fff !important;

    .streaming-icon {
        filter: invert(54%) sepia(99%) saturate(2886%) hue-rotate(185deg) brightness(96%) contrast(91%);
    }
}

.option-selected {
    border-color: #57b2f7;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.14);
    background: #fff;

    .streaming-icon {
        filter: invert(54%) sepia(99%) saturate(2886%) hue-rotate(185deg) brightness(96%) contrast(91%);
    }
}


.orientation-card {
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--brand-blueBlack-20);
    min-height: 100%;
}

.orientation-card-selected {
    border-color: #57b2f7;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.14);
    background: #fff !important;

    .streaming-icon {
        filter: invert(54%) sepia(99%) saturate(2886%) hue-rotate(185deg) brightness(96%) contrast(91%);
    }
}

.ortn-content {
    display: flex;
    justify-content: center;
    text-wrap: nowrap !important;
}


.ortn-arrow {
    font-size: 14px;
}

.orientation-conatiner {
    max-width: 147px;
    height: 100px;
    max-height: 100px;
    text-align: center;
    display: flex;
}

.orientation-conatiner img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: auto;
}

.media-type-header {
    background: var(--color-bg-bluelight);
    border: 1px solid var(--brand-blueBlack-30);
    border-radius: 8px;
    padding: 16px;

    &-content {
        color: #70767D;
        display: flex;
        align-items: center;
    }

    &-img-wrapper {
        width: 32px;
        height: 32px;
    }
}

.content-center {
    align-content: center;
    align-self: center;
}

.empty-list-container {
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*---------------------------- tag --------------------------*/

.selected-chip-container {
    width: 100%;
    min-height: 140px;
    background: var(--brand-blueBlack-3);
    border: 1px solid var(--brand-blueBlack-30);
    border-radius: 6px;
    padding: 16px;
    overflow: hidden;

}

.selected-tag {
    width: fit-content;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 10px 10px 0;
    color: var(--text-Black-85);
    font-size: 14px;
    display: inline-flex !important;
    align-items: center;
    height: 32px;
    padding: 0 7px;
    white-space: nowrap;
    background: #ABD3F7;
    border: none;
    border-radius: 2px;
    opacity: 1;
    transition: all .3s;
    align-content: center;
}

body.darkmode .selected-tag {
    background: var(--brand-blue-50);
    color: #201F1E;
}

.selected-tag-content {
    display: inline-block;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.ant-tag-close-icon {
    margin-left: 10px;
    font-size: 12px !important;
}

/*----------------------------- Ant Step ----------------------------*/

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: var(--brand-blue-100);
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #359B35;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: var(--brand-blue-20);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: var(--brand-blue-20);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: var(--brand-blue-100);
    font-size: 14px;
    font-weight: 600;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: var(--text-Black-85);
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:hover,
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:hover,
.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:hover {
    color: #2C92EA;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: var(--brand-blueBlack-70);
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: #359B35;
}

.ant-dropdown {
    width: 170px;
}

.ant-dropdown-menu {
    padding: 0px 0px;
    border-radius: 10px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    height: 44px;
    line-height: 35px;
    border-radius: 10px;
    display: flex;
    gap: 13px;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
    background-color: #094179;
}


@media (max-width: 1200px) {
    .right-layout-250 {
        position: fixed;
        left: 80px;
    }
}

@media (max-width: 1100px) {
    .subscription-grid {
        display: flex;
        grid-column-gap: 0px;
        justify-content: space-between;
    }
}

@media (max-width: 991px) {
    .preview-card {
        position: relative;
        top: 0;
        margin-right: 0px;
        width: auto;
        height: auto;
        min-height: auto;
    }
}

@media (max-width: 768px) {
    .ant-modal {
        min-width: auto;
    }

    .page-header {
        padding: 15px;
        height: auto;
        background: #fff;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.14);
    }

    .right-layout-250 {
        position: relative;
        left: 0px;
    }

    .right-layout-80 {
        position: relative;
        left: 0px;
    }

    .ant-card-body {
        padding: 15px;
    }

    .min-h-350 {
        height: auto;
    }
}

@media (max-width: 576px) {
    .payment-card-actions {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding-left: 70px;
    }

    .disp-web {
        display: none;
    }

    .disp-web-subs {
        display: none;
    }

    .disp-web-button {
        display: none;
    }

    .disp-mob {
        display: inline-flex !important;
    }

    .disp-mob-subs {
        display: block;
    }

    .disp-mob-subs {
        display: block !important;
    }

    .mobOne-action-m-b {
        margin-bottom: 70px !important;
    }

    .mob-mt-0 {
        margin-top: 0px !important;
    }

    .mob-pb-3 {
        padding-bottom: 15px !important;
    }

    .mob-w-50 {
        width: 50%;
    }

    .mob-w-70 {
        width: 70%;
    }

    .ant-drawer-right>.ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .orientation-conatiner {
        width: 50px;
        max-width: 50px;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .orientation-card .ant-card-body {
        padding: 15px 5px;
    }

    .streaming-card .ant-card-body {
        padding: 15px !important;
    }

    .list-img-wrapper {
        width: 30px;
        height: 30px;
    }

    .list-img-wrapper img {
        width: 30px;
        height: 30px;
    }

    .ant-btn {
        font-size: 13px;
    }

    .ant-modal {
        top: 0;
        width: auto !important;
    }

    .ant-card-bordered {
        border-radius: 8px;
    }

    .panel-ml {
        margin-left: 0px;
    }
}

@media (max-width: 460px) {
    .orientation-conatiner {
        width: 45px;
        max-width: 45px;
        height: 50px !important;
        max-height: 50px !important;
        text-align: center;
    }

    .orientation-conatiner img {
        max-width: 45px;
        max-height: 45px;
    }

    .ortn-content {
        font-size: 12px;
        margin: 10px 0px !important;
    }

    .orientation-card .ant-card-body {
        padding: 15px 0px 0px 0px;
    }

    .small-input {
        width: 65px;
    }
}

@media (max-width: 360px) {
    .orientation-conatiner {
        width: 45px;
        max-width: 45px;
        max-height: 40px;
        text-align: center;
    }

    .orientation-conatiner img {
        max-width: 35px;
        max-height: 35px;
    }

    .ortn-content {
        font-size: 12px;
        margin: 10px 0px !important;
    }

    .orientation-card .ant-card-body {
        padding: 10px 0px 0px 0px;
    }
}

.validation-icon {
    font-size: 12px !important;
}

/*----------------------- tags --------------------------------*/
.selected-chip-container {
    width: 100%;
    min-height: 140px;
    background: var(--brand-blueBlack-3);
    border: 1px solid var(--brand-blueBlack-30);
    border-radius: 6px;
    padding: 16px;
}

.selected-tag {
    box-sizing: border-box;
    margin: 0 10px 10px 0;
    color: var(--text-Black-85);
    font-size: 14px;
    display: inline-block;
    height: 32px;
    padding: 0 7px;
    white-space: nowrap;
    background: #A3C2DE;
    border: none;
    border-radius: 2px;
    opacity: 1;
    transition: all .3s;
    align-content: center;
}

.ant-tag-close-icon {
    margin-left: 10px;
    font-size: 12px !important;
}









/*=======Importing Layout Darkmode========*/

@import './_layout-darkmode';
@import './_layout-darkmode2';