body:not(.darkmode) {
    .darkmode_img {
        display: none !important;
    }
}

// =====================================
body.darkmode {
    .lightmode_img {
        display: none !important;
    }

    .filter_darkmode {
        filter: brightness(0) invert(1);
    }

    .text-muted {
        color: var(--brand-blueGray-70) !important;
    }

    .ant-btn:not(.ant-btn-primary) {
        background: transparent;
        border-color: var(--brand-blueBlack-20);
        color: var(--text-white);
    }

    .ant-btn.ant-btn-primary[disabled],
    .ant-btn[disabled] {
        background: var(--brand-blueBlack-20);
        border-color: transparent;
        color: var(--text-blueBlack-50);
    }

    .list-default-wrapper {
        background: transparent;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: var(--brand-blueGray-20);
    }

    // ------Define White color to Elements-------
    &,
    .ant-layout-header,
    .ant-card,
    .ant-tabs,
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
    .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
    .ant-select-dropdown,
    .ant-select-item,
    .ant-form-item,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .ant-form-item-label>label,
    .ant-form,
    .ant-modal-title,
    .ant-modal-close-x .anticon,
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    .ant-divider-plain.ant-divider-with-text,
    .ant-checkbox,
    .ant-checkbox-wrapper,
    .ant-checkbox-disabled+span,
    .ant-upload,
    .ant-collapse>.ant-collapse-item>.ant-collapse-header,
    .ant-upload.ant-upload-drag p.ant-upload-text,
    .ant-upload.ant-upload-drag p.ant-upload-hint {
        color: var(--text-white);
    }

    // ------Define All Popup & Modal Background--------
    .ant-dropdown-menu,
    .ant-modal-content {
        background: var(--modal-bg-color);
        color: var(--text-blueBlack-100);
    }

    // ------Define Card style --------
    .card,
    .ant-card:not(.media-card),
    .ant-menu-submenu-popup>.ant-menu {
        background: var(--brand-blueBlack-0);
        border-color: transparent;
    }

    .sidenav-lis-items {
        background: var(--brand-blueBlack-20);

        .feedback-menu {
            background: var(--brand-blueBlack-20);

            a:not(:hover) {
                color: var(--text-white);
            }

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    .media-type-header {
        background: var(--blue-bg-color);

        .media-type-header-content {
            color: var(--text-white);

            a {
                color: var(--brand-blueBlack-80);
            }
        }
    }

    // ----------Define the white ----------
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: var(--color-bg-bluelight);
    }

    // ----------Other style--------------
    .side-bar-input .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-dropdown {
        background: var(--brand-blueBlack-3);
        color: var(--text-blueBlack-40);
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: var(--text-blueBlack-40);
        background: transparent;
        border-color: var(--brand-blueBlack-30);
    }

    // --modal-bg-color
    // -------------------
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active,
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background: var(--brand-blueBlack-0);
    }

    // ----------Form Style------------

    .ant-input-affix-wrapper {
        border-color: var(--brand-blueBlack-30);
    }

    .ant-checkbox-inner {
        background: var(--brand-blueBlack-0) !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #2c92ea !important;
        border-color: #2c92ea !important;
    }

    .selected-square .ant-checkbox-checked .ant-checkbox-inner:after {
        background: #2c92ea;
        border-radius: 0px;
    }

    .selected-square .ant-checkbox-checked .ant-checkbox-inner {
        background-color: transparent !important;
        border-color: var(--brand-blueBlack-30) !important;
    }

    .ant-input,
    .ant-input:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        background: transparent;
        border-color: var(--brand-blueBlack-30);
        color: var(--text-white);

        &:focus {
            border-color: #57b2f7;
        }
    }

    .ant-input.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {

        &,
        &:hover {
            border-color: var(--text-error);
        }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        color: var(--text-white);
    }

    .ant-select-single.ant-select-open .ant-select-selection-item{
        color: var(--text-white);
        opacity: 0.3;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-affix-wrapper,
    .ant-carousel .slick-dots li,
    .drawer-search-input .ant-input-affix-wrapper>.ant-input:not(textarea),
    .ant-picker-status-error.ant-picker,
    .ant-picker-status-error.ant-picker:not(.ant-picker-disabled):hover,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
        background: transparent;
    }

    .ant-input-textarea-show-count:after {
        color: var(--text-white);
    }

    .ant-switch {
        color: var(--text-white);
        border-color: var(--text-white);

        &.ant-switch-checked {
            background: var(--brand-blue-10);
            border-color: var(--brand-blue-10);
            outline: none;
        }
    }

    .ant-switch-handle:before {
        background: var(--text-white);
    }

    .ant-radio-wrapper,
    .ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
        color: var(--text-white);
    }

    .ant-picker,
    .ant-picker-input>input {
        color: var(--text-white);
        background: transparent;

        &:not(:focus):not(:active):not(:focus-visible):not(:focus-within) {
            border-color: var(--brand-blueBlack-30);
        }
    }

    .ant-radio-wrapper {
        .ant-radio-inner {
            border-color: #ADADAD;
            background: transparent;
        }

        &.ant-radio-wrapper-checked {
            .ant-radio-inner {
                border-color: var(--brand-blue-10);
                // background: var(--brand-blue-10);
            }
        }

    }

    input::placeholder {
        color: var(--brand-blueBlack-40) !important;
    }

    .ant-select-disabled {
        .ant-select-selector {
            background: var(--brand-blueBlack-20) !important;
            border-color: var(--brand-blueBlack-30) !important;
            color: var(--brand-blueBlack-50) !important;

            .ant-select-selection-item {
                color: var(--brand-blueBlack-50) !important;
            }
        }
    }

    .ant-input-affix-wrapper-disabled {
        background: var(--brand-blueBlack-20) !important;
        border-color: var(--brand-blueBlack-30) !important;
        color: var(--brand-blueBlack-50) !important;

        [disabled="true"],
        .anticon {
            color: var(--brand-blueBlack-50) !important;
        }

    }
    .ant-select .ant-select-selection-placeholder,textarea::placeholder {
        color: var(--text-blueBlack-40);
    }

    // -----------------

    .menu-sidebar,
    .drawer-menu {
        .sidebar-icon {
            filter: brightness(0) invert(1);
        }
    }

    .ant-menu {
        color: #fff;
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right: 0 none;
    }

    .header-input .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: var(--brand-blueBlack-20);
    }

    .ant-divider {
        border-top-color: var(--brand-blueBlack-20);
    }

    .ant-drawer-body {
        .ant-divider {
            border-top-color: var(--brand-blueBlack-10);
        }
    }

    .ant-card.media-card.select-option:not(:hover) {
        background: transparent;
        border-color: #414951;
    }

    .select-option:hover,
    .option-selected,
    .orientation-card-selected,
    .ant-card.media-card.select-option {
        background: transparent !important;

        &.option-selected {
            border-color: #57b2f7;
        }
    }

    .select-option .streaming-icon {
        filter: invert(54%) sepia(99%) saturate(2886%) hue-rotate(185deg) brightness(96%) contrast(91%);
    }

    .streaming-card .streaming-container.select-option,
    .streaming-card .streaming-container.bg-gray {
        background: var(--brand-blueDark-10);
        border-color: var(--brand-blueDark-10);

        .streaming-icon {
            filter: brightness(0) invert(1);
        }

        &:hover,
        &.orientation-card-selected {
            background: transparent;
            border-color: var(--brand-blue-10);

            .streaming-icon {
                filter: invert(54%) sepia(99%) saturate(2886%) hue-rotate(185deg) brightness(96%) contrast(91%)
            }
        }
    }

    .ant-modal-header {
        color: var(--text-white);
        background: var(--modal-bg-color);
    }

    .ant-rate-star:not(.ant-rate-star-full) {

        .ant-rate-star-first,
        .ant-rate-star-second {
            color: var(--brand-blueBlack-20);
        }
    }

    .preview-content {
        background: var(--brand-blueBlack-20);
    }

    .ant-tabs-top>.ant-tabs-nav:before {
        border-bottom-color: transparent;
    }

    // --------------Table Style---------
    .ant-table {
        background: transparent;
        color: var(--text-white);
    }

    .ant-table-thead>tr>th {
        background: var(--brand-blueBlack-20);
        color: var(--text-white);
        border-color: #28313B;
    }

    .ant-table-tbody>tr>td {
        border-color: #28313B;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td,
    .ant-table-tbody>tr>td.ant-table-cell-row-hover {
        background: #28313B;
    }

    a{
        color: var(--text-blue-100);
    }

    .ant-table-tbody>tr.ant-table-placeholder:hover>td {
        background: transparent;
    } 

    // ----------------------------------
    .dashboard-tile-chip {
        background: var(--brand-blueDarkest-30);
        color: var(--brand-blueBlack-90);
    }

    .ant-carousel .slick-dots li button {
        border-radius: 50%;
    }

    .ant-pagination-item-active {
        background: transparent;
    }

    .ant-pagination-item a:not(:hover) {
        color: var(--text-white);
    }

    .form-action-bar {
        box-shadow: 0 -5px 2px #0000001f, 0 1px 2px #00000024;
        background: var(--brand-dropdown);
        .ant-btn:not(.ant-btn-primary) {
            background: var(--brand-blueBlack-0);
        }
    }

    .content-container .header-input .ant-input-affix-wrapper {
        background: var(--brand-blueBlack-0);
        border: 1px solid var(--brand-blueBlack-30);

        svg {
            color: var(--text-white);
        }
    }

    .ant-drawer-content .ant-drawer-body {
        background: var(--brand-blueBlack-0) !important;
    }

    .ant-drawer-content-wrapper {
        .ant-input-affix-wrapper {
            background-color: var(--brand-blueBlack-3);
            border-color: var(--brand-blueBlack-30);

            svg {
                color: var(--text-white);
            }
        }
    }

    .drawer-form-header,
    .drawer-form-header-empty {
        background: var(--brand-blueBlack-0);
    }

    .main-content {
        .header-input .ant-input-affix-wrapper {
            border: 1px solid var(--brand-blueBlack-30);

            .anticon-search {
                color: var(--text-white);
            }
        }
    }

    .card-with-title-bg-blue {
        background: #4F5165;
        color: var(--text-white);
    }

    .plan-container.chip_const:not(.bg-red) {
        background: #D1E1EE;
        color: var(--text-blue-100);
        border-color: #A3C2DE;
    }

    .ant-upload.ant-upload-select-picture-card {
        background: #313C48;
        border-color: var(--brand-blueDarkest-40);
        color: var(--text-white);
    }

    .ant-upload.ant-upload-drag {
        background: #313C48;
        border-color: var(--brand-blueDarkest-40);

        .upload-text {
            color: var(--text-blue-100);
        }
    }

    .gbl_media_card {
        .ant-card.list-card {
            background: var(--brand-blue-10) !important;
            border-color: var(--brand-blueDark-40);
            border-radius: 6px;
            color: var(--text-white);

            .action-btn {
                background: var(--brand-blueBlack-20);
            }
        }

        .size-wrapper {
            color: var(--text-white);
        }
    }

    .disp-web-subs {
        .detail-card-blue {
            background: var(--brand-blueDarkest-30);
            border-radius: 5px;

            .ant-input.ant-input-stepperless {
                background: var(--brand-blueBlack-3);
            }
        }

        .detail-card-gray {
            background: var(--brand-blueBlack-10);
        }

        .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
            background: transparent !important;
        }
    }

    .custom-switich {
        .ant-switch {
            background: var(--brand-disabled);
        }

        .ant-switch-handle:before {
            background: var(--brand-blueBlack-0);
        }
    }

    .plantable__wrapper {

        .ant-table-content,
        .ant-table-tbody>tr.ant-table-row>td {
            background: transparent;

            .ant-table-thead>tr>th {
                background: transparent;
            }
        }

        .ant-table-thead>tr>th.plandetails__card:hover {
            background: transparent !important;
        }
    }

    .proplan_wrapper {

        .ant-table-thead>tr>th,
        .ant-table-tbody>tr>td {

            &.bg-blue,
            &.bg-blue:hover {
                background: var(--brand-blueDarkest-30) !important;
            }
        }
    }

    .pageheader__tabs {
        .card-heading.bg-blue {
            background: var(--brand-blueDarkest-30);
        }

        .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
            background: transparent !important;
        }
    }

    .ant-select-item-empty {
        color: var(--text-white);
    }

    .preview-display-dynamic-container {
        background: #1F2832;
    }

    .ant-checkbox-inner {
        border-color: var(--brand-blueBlack-30);
    }

    input:-webkit-autofill {
        background-color: transparent !important;
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
        color: var(--text-white) !important;
    }
    .ant-card.orientation-card{
        border-color: var(--brand-blueBlack-20);
    }
    .orientation-card-selected.ant-card.orientation-card {
        border-color: #57b2f7;
    }

    .preview-chip {
        border-color: var(--brand-blueBlack-60);
        color: var(--brand-blueBlack-100);
        background: var(--brand-blueBlack-40);

        .text-muted {
            color: var(--text-blueBlack-70);
        }
    }

    .chip-primary {
        background: var(--brand-blue-10);
    }

    .ant-picker:not(:focus):not(:active):not(:focus-visible):not(:focus-within) {
        border-color: var(--brand-blueBlack-30);
    }

    .ant-spin-container:after {
        background: var(--brand-blueBlack-3);
    }

    .disabled-streaming-container {
        border-color: var(--brand-blueBlack-30);
        background: var(--brand-blueBlack-20) !important;
        cursor: not-allowed;
    }

    .plan-container.alert_chip.bg-blue {
        border-color: #A3C2DE;
        background: #D1E1EE;
        color: var(--text-blue-100);
    }

    .ant-menu-item a {
        color: var(--text-white);
    }

    .small-input .ant-input {
        background: var(--brand-blueBlack-3) !important;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background: transparent;
    }

    .streaming-card .ant-card.list-card,
    .ant-drawer-body .ant-card.list-card {
        border-color: var(--brand-blueBlack-20);
    }

    .status-icon-primary {
        color: var(--text-blue-100);
    }

    .btn-with-google {
        background: var(--brand-blueBlack-0);
    }
    .ant-card.ant-card-bordered .custom-chip {
        border-color: var(--brand-blueDarkest-40);
        color: var(--text-white);

    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .active-list{
        background: var(--text-blue-100) !important;
    }
    .action_sidebar_wrapper{
        .selected-button{
            border-color: #fff;
        }
    }
 
}

// ==========================
