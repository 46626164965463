@import './variable-light';

body.darkmode {
    --brand-blue-100: #EAF4FD;
    --brand-blue-90: #D5E9FB;
    --brand-blue-80: #c0c0c0;
    --brand-blue-70: #ABD3F7;
    --brand-blue-60: #95C9F5;
    --brand-blue-50: #80BEF2;
    --brand-blue-40: #6BB3F0;
    --brand-blue-30: #56A8EE;
    --brand-blue-20: #419DEC;
    --brand-blue-10: #2C92EA;


    // Blue Gray =========

    --brand-blueGray-100: #EAEBEC;
    --brand-blueGray-90: #D5D7D9;
    --brand-blueGray-80: #C1C3C5;
    --brand-blueGray-70: #ACAFB2;
    --brand-blueGray-60: #979B9F;
    --brand-blueGray-50: #82878C;
    --brand-blueGray-40: #6d7379;
    --brand-blueGray-30: #595F65;
    --brand-blueGray-20: #444B52;
    --brand-blueGray-10: #2F373F;
    --brand-blueGray-5: #2a323a;

    // Dark Bule=================

    --brand-blueDark-100: #e7e7e7;
    --brand-blueDark-90: #CFD9E2;
    --brand-blueDark-80: #B8C6D3;
    --brand-blueDark-70: #A0B3C5;
    --brand-blueDark-60: #88A0B6;
    --brand-blueDark-50: #708EA8;
    --brand-blueDark-40: #587B99;
    --brand-blueDark-30: #41688B;
    --brand-blueDark-20: #29557D;
    --brand-blueDark-10: #11426E;

    // Darkest Blue =============

    --brand-blueDarkest-100: #E9E9EC;
    --brand-blueDarkest-90: #D3D3D9;
    --brand-blueDarkest-80: #BDBEC5;
    --brand-blueDarkest-70: #A7A8B2;
    --brand-blueDarkest-60: #91929F;
    --brand-blueDarkest-50: #7B7C8C;
    --brand-blueDarkest-40: #656679;
    --brand-blueDarkest-30: #4F5165;
    --brand-blueDarkest-20: #393B52;
    --brand-blueDarkest-10: #23253F;

    // Black Blue =============

    --brand-blueBlack-100: #F8F8F8;
    --brand-blueBlack-90: #E7E8E9;
    --brand-blueBlack-80: #CFD1D4;
    --brand-blueBlack-70: #B8BBBE;
    --brand-blueBlack-60: #C3CAD3;
    --brand-blueBlack-50: #888D92;
    --brand-blueBlack-40: #70767D;
    --brand-blueBlack-30: #585F67;
    --brand-blueBlack-20: #414951;
    --brand-blueBlack-10: #28313B;
    --brand-blueBlack-3: #111B26;
    --brand-blueBlack-0: #2C3540;
    --brand-dropdown: #121418;
    --brand-disabled: #313C48;
    // =========Text color===========
    --text-blueBlack-50: #A9B2BB;
    --text-blueBlack-100: #f8f8f8;
    --text-white: #f8f8f8;
    --text-blue-100: #72B4ED;
    --text-blueBlack-40: #70767d;
    --text-blueBlack-70: #b8bbbe;
    --text-blueBlack-60: #a0a4a8;
    --text-Black: #fff;
    --text-Black-85:#fff;
    --text-error: #FF1C30;
    // =========Background Other Color=========
    --card-bg-white:var(--brand-blueBlack-0);
    --modal-bg-color:#292929;
    --blue-bg-color:#3176B4;
    --color-bg-bluelight:var(--brand-blueDarkest-20);
    --color-bg-bluelight2:var(--brand-blueDarkest-20);
}