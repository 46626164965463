
body.darkmode{
    .ant-layout-header,.ant-card,.ant-tabs{
        color: var(--text-blueBlack-100);
    }
    .ant-table-column-sorter{
        color: var(--text-blueBlack-100);
    }
    .ant-breadcrumb-separator {
        color: var(--text-Black-85);
    }

    .anticon.anticon-calendar{
        color: var(--text-blueBlack-100);
    }

    .ant-pagination .ant-pagination-item-link span{
        color: var(--text-blueBlack-100);
    }

    .ant-pagination  .ant-select-arrow span{
        color: var(--text-blueBlack-100) !important;
    }

    .input-container .ant-form-item .ant-form-item-control-input .ant-select-arrow{
        color: var(--brand-blueBlack-100);
    }

    .ant-input-affix-wrapper .ant-input-prefix span{
        color: var(--text-blueBlack-100);
    }

    .ant-input-affix-wrapper .ant-input-suffix .anticon{
        color: var(--text-blueBlack-100);
    }
}
