:root {
    --brand-blue-100: #2C92EA;
    --brand-blue-90: #419DEC;
    --brand-blue-80: #56A8EE;
    --brand-blue-70: #6BB3F0;
    --brand-blue-60: #80BEF2;
    --brand-blue-50: #95C9F5;
    --brand-blue-40: #ABD3F7;
    --brand-blue-30: #c0c0c0;
    --brand-blue-20: #D5E9FB;
    --brand-blue-10: #EAF4FD;

    // Blue Inv  =========

    --brand-blue2-100: #2C92EA;
    --brand-blue2-90: #419DEC;
    --brand-blue2-80: #56A8EE;
    --brand-blue2-70: #6BB3F0;
    --brand-blue2-60: #80BEF2;
    --brand-blue2-50: #95C9F5;
    --brand-blue2-40: #ABD3F7;
    --brand-blue2-30: #c0c0c0;
    --brand-blue2-20: #D5E9FB;
    --brand-blue2-10: #EAF4FD;
    --brand-blue2-5: #F4FAFE;

    // Blue Gray =========

    --brand-blueGray-100: #2F373F;
    --brand-blueGray-90: #444B52;
    --brand-blueGray-80: #595F65;
    --brand-blueGray-70: #6d7379;
    --brand-blueGray-60: #82878C;
    --brand-blueGray-50: #979B9F;
    --brand-blueGray-40: #ACAFB2;
    --brand-blueGray-30: #C1C3C5;
    --brand-blueGray-20: #D5D7D9;
    --brand-blueGray-10: #EAEBEC;
    --brand-blueGray-5: #EFEFEF;

    // Dark Bule=================

    --brand-blueDark-100: #11426E;
    --brand-blueDark-90: #29557D;
    --brand-blueDark-80: #41688B;
    --brand-blueDark-70: #587B99;
    --brand-blueDark-60: #708EA8;
    --brand-blueDark-50: #88A0B6;
    --brand-blueDark-40: #A0B3C5;
    --brand-blueDark-30: #B8C6D3;
    --brand-blueDark-20: #CFD9E2;
    --brand-blueDark-10: #e7e7e7;

    // Darkest Blue ==============

    --brand-blueDarkest-100: #23253F;
    --brand-blueDarkest-90: #393B52;
    --brand-blueDarkest-80: #4F5165;
    --brand-blueDarkest-70: #656679;
    --brand-blueDarkest-60: #7B7C8C;
    --brand-blueDarkest-50: #91929F;
    --brand-blueDarkest-40: #A7A8B2;
    --brand-blueDarkest-30: #BDBEC5;
    --brand-blueDarkest-20: #d3d3d9;
    --brand-blueDarkest-10: #E9E9EC;

    // Black Blue =============

    --brand-blueBlack-100: #111B26;
    --brand-blueBlack-90: #29323C;
    --brand-blueBlack-80: #414951;
    --brand-blueBlack-70: #585F67;
    --brand-blueBlack-60: #70767D;
    --brand-blueBlack-50: #888D92;
    --brand-blueBlack-40: #A0A4A8;
    --brand-blueBlack-30: #B8BBBE;
    --brand-blueBlack-20: #CFD1D4;
    --brand-blueBlack-10: #E7E8E9;
    --brand-blueBlack-3: #F8F8F8;
    --brand-blueBlack-0: #fff;

    // =========Text color===========
    --text-blueBlack-50: #888D92;
    --text-blueBlack-100: #111B26;
    --text-blue-100: #2C92EA;
    --text-blueBlack-40: #A0A4A8;
    --text-blueBlack-70: #585F67;
    --text-Black: #000;
    --text-Black-85: #000000d9;
    
    // =========Text Other Color=========
    --color-gray1:#605f5f;
    // =========Background Other Color=========
    --card-bg-white:var(--brand-blueBlack-0);
    --color-bg-gray1:#d9d9d9;
    --color-bg-bluelight: #D1E1EE;
    --color-bg-bluelight2: #e6f7ff;
 }